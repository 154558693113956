<template>
    <div class="w-100">
        <div class="p-3">
            <h1 style="opacity: 0.7; font-size: 1.25rem;" class="generic-typography">{{ $gettext("Industries") }}</h1>
        </div>

        <b-row>
            <b-col cols="12" lg="6" md="12" sm="12">
                <b-form class="customized-form w-100">
                    <ValidationObserver ref="edit_candidate_industry" tag="div">
                        <b-row class="p-1 px-3">
                            <div class="col-12">
                                <ValidationProvider rules="required|Industry" name="industry" v-slot="{ errors, classes }">
                                    <div class="custom-placeholder">
                                        {{ search ? '' : $gettext("Select_Industry") }}
                                    </div>
                                    <v-select id="user-industry" clearable multiple placeholder="" class="form-control"
                                        :class="classes" v-model="industry" @search="searchIndustry"
                                        @input="validateIndustryMin1Max3(industry)"
                                        :get-option-label="(option) => option.speciality" :options="industryOption">
                                    </v-select>
                                    <div class="error-msg" :class="classes">
                                        <span>{{ errors[0] }}</span>
                                        <!-- <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span> -->
                                    </div>
                                </ValidationProvider>
                                <!-- <ValidationProvider name="industry" v-slot="{ errors, classes }">
                                    <div class="custom-placeholder">
                                        Select Industry
                                    </div>
                                    <v-select id="user-industry" clearable multiple placeholder="" class="form-control"
                                        :class="classes" v-model="industry" :options="industryOption">
                                    </v-select>
                                    <div class="error-msg" :class="classes">
                                        <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                    </div>
                                </ValidationProvider> -->
                            </div>
                            <!-- <div class="selected-industry-skill m-2 px-3"> -->
                            <div class="selected-industry-skill m-2 px-3">
                                <span v-for="(ins, i) in industry" class="vs__selected" :key="i">
                                    {{ ins.speciality }}
                                    <img style="cursor: pointer;" @click="removeIndustry(i)" class="ml-2"
                                        src="@/assets/zaajira-candidate/assets/icons/cross.svg" alt="" />
                                </span>
                            </div>
                        </b-row>
                        <div class="col-12 my-4 btn-flex-fd-row-jc-sb">
                            <b-button @click.prevent.stop="ValidateInput" variant="primary" size="sm">{{
                                $gettext("Save_change") }}
                            </b-button>
                        </div>
                    </ValidationObserver>
                </b-form>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import CandidateService from "@/apiServices/CandidateService";
import MasterServices from "@/apiServices/MasterServices";

export default {
    name: "Edit-Industry",
    data() {
        return {
            search: null,
            industry: null,
            industryOption: [],
        }
    },
    beforeMount() {
        this.PreFilledData()
    },
    methods: {
        goBack() {
            this.$router.push({ path: 'personal-details' })
        },
        validateIndustryMin1Max3(industry) {
            console.log("industry", industry);
            console.log("this.industry", this.industry);
        },
        searchIndustry(s, loading) {
            this.search = s
        },
        removeIndustry(index) {
            console.log(index);
            this.industry.splice(index, 1)
        },
        async getAllIndustry() {
            try {
                const AllIndustryRes = await MasterServices.GetAllIndustry()
                console.log("AllIndustryRes", AllIndustryRes.data.data.types);
                return AllIndustryRes.data.data.types;
            } catch (error) {

            }
        },
        async GetMasterData() {
            this.industryOption = await this.getAllIndustry()

        },
        async PreFilledData() {
            let loader = this.$loading.show();
            await this.GetMasterData()
            const userData = this.$store.getters.getLoggedUser
            console.log("userData.userId", userData.userId);
            CandidateService.GetselectedIndustry(userData.userId)
                .then((res) => {
                    console.log("GetselectedIndustry", res.data.data.types);
                    this.industry = res.data.data.types
                    loader.hide()
                })
                .catch((error) => {
                    console.log("error", error);
                    loader.hide()
                })

        },
        ValidateInput() {
            return this.$refs.edit_candidate_industry.validate().then(async (result) => {
                if (result) {
                    console.log(result);
                    const speciality_id = this.industry.map((ind) => ind.speciality_id)
                    let industryString = speciality_id.join(',')

                    const payload = {
                        job_industry_ids: industryString
                    }
                    console.log('payload', payload);
                    let loader = this.$loading.show();
                    CandidateService.updateUser(payload)
                        .then((res) => {
                            // console.log(res);
                            // console.log('res', res.data.data);
                            // console.log('res', res.data.message);
                            this.$toasted.show(`${res.data.message}`, {
                                duration: 6000,
                                type: "success",
                                icon: "check",
                            });
                            const storePayload = {
                                userIndustry: speciality_id
                            }
                            // console.log('storePayload', storePayload);
                            this.$store.dispatch("onSetSelectedIndustry", storePayload);

                            // this.$router.push({ name: "Skill-Details" })
                            loader.hide();
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                    // this.$router.push({ path: 'skill-details' })
                }
            })
        }
    },


}
</script>

<style></style>